import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import ReactGA from 'react-ga';
import { useAccount } from 'wagmi';
import Button from '@/shared/components/flip-ui-kit/Button';
import useAuction from '@/shared/hooks/useAuction';
import useTracking from '@/shared/hooks/useTracking';
import AddIcon from '@/shared/icons/Add';
import AuctionStatPanels from '../components/auction/AuctionStatPanels';
import AuctionTable from '../components/auction/AuctionTable';
import { Layout } from '../components/Layout';
import { RegisterNodeModal } from '../components/RegisterNodeModal';
import { getAllValidatorsQuery } from '../queries/validatorQueries';
import { StakeEvents, type StakeTrackEvents } from '../types/track';
import { POLL_INTERVAL } from '../utils/consts';

export default function Home(): JSX.Element | null {
  ReactGA.pageview('auctions');
  const track = useTracking<StakeTrackEvents>();
  const { isConnected } = useAccount();
  const [addNodeModalActive, setAddNodeModalActive] = useState(false);
  const [canMount, setCanMount] = useState(false);

  const {
    auction,
    loading: isAuctionsLoading,
    error: auctionsError,
  } = useAuction({ pollInterval: POLL_INTERVAL });

  const { data: validatorsData, loading: validatorsLoading } = useQuery(getAllValidatorsQuery, {
    pollInterval: POLL_INTERVAL,
  });

  const validators = validatorsData?.validators?.nodes ?? [];
  const qualifiedNodes = validators.filter((v) => v.isQualified).length;

  // the add node button will remain disabled due to next or ssr or some bs that
  // we just don't have the time or inclination to investigate. so instead we
  // don't SSR this page!
  useEffect(() => {
    setCanMount(true);
  }, []);

  if (!canMount) return null;

  return (
    <Layout>
      <div className="flex-col">
        <div className="flex w-full flex-row items-center justify-between pb-14 pt-4">
          <div className="text-35 text-white">Auctions</div>
          <Button
            size="large"
            className="hidden sm:flex"
            iconPos="left"
            icon={<AddIcon className="-ml-2 stroke-current" />}
            onClick={() => {
              track(StakeEvents.AddNode, {
                props: {
                  path: window.location.pathname,
                  position: 'table_top_right',
                },
              });
              setAddNodeModalActive(true);
            }}
            disabled={!isConnected}
          >
            Add node
          </Button>
        </div>
        <AuctionStatPanels
          auction={auction}
          isLoading={isAuctionsLoading}
          qualifiedNodes={qualifiedNodes}
          error={auctionsError}
        />
        <AuctionTable validators={validators} isLoading={validatorsLoading} />
      </div>
      <RegisterNodeModal active={addNodeModalActive} onClose={() => setAddNodeModalActive(false)} />
    </Layout>
  );
}
