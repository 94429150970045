import { useMemo, useState } from 'react';
import useCounter from './useCounter';
import { type BasePaginationData } from './useRemotePagination';

export interface LoadedPaginationData<T> extends BasePaginationData {
  loading: false;
  data: {
    pageInfo: {
      hasPreviousPage: boolean;
      startCursor: string;
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: {
      cursor: string;
      node: T;
    }[];
    totalCount: number;
  };
  error: null;
}

export default function useFrontendPagination<T>(
  data: T[],
  {
    initialLimit = 20,
    paginationLimits = [20, 40, 60, 80, 100],
  }: { initialLimit?: number; paginationLimits?: number[] } = {},
): LoadedPaginationData<T> {
  const [limit, setLimit] = useState(initialLimit as number);

  const totalPages = useMemo(() => Math.ceil(data.length / limit), [limit, data.length]);

  const pageCounter = useCounter({ max: totalPages, start: 1 });

  const result = useMemo(() => {
    const offset = limit * (pageCounter.current - 1);
    return data.slice(offset, offset + limit).map((node) => ({ node, cursor: '' }));
  }, [pageCounter.current, limit, data]);

  const paginate = useMemo(
    () => ({
      data: {
        totalCount: data.length,
        edges: result,
        pageInfo: {
          hasPreviousPage: !pageCounter.atBeginning,
          startCursor: '',
          hasNextPage: !pageCounter.atEnd,
          endCursor: '',
        },
      },
      error: null,
      loading: false,
      paginationControls: {
        pageCounter,
        limit,
        setLimit: (newLimit: number) =>
          setLimit(paginationLimits.includes(newLimit) ? newLimit : 20),
        paginationLimits,
      },
    }),
    [pageCounter, limit, result, data.length],
  );

  return paginate as LoadedPaginationData<T>;
}
