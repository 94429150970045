import { type ApolloError, useQuery } from '@apollo/client';
import FlipAmount from '@/shared/utils/FlipAmount';
import type TokenAmount from '@/shared/utils/TokenAmount';
import getLatestFlipSupplyQuery from '../queries/flipSupplyQueries';

export interface FlipSupplyHook {
  totalFlipSupply: string | undefined;
  annualSystemCompoundedRewards: TokenAmount | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

const useFlipSupply = (): FlipSupplyHook => {
  const { loading, error, data } = useQuery(getLatestFlipSupplyQuery);
  const flipSupply = data?.flipSupply;
  const totalFlipSupply = FlipAmount.from(flipSupply?.totalIssuance);

  const annualSystemCompoundedRewards =
    FlipAmount.from(flipSupply?.annualSystemCompoundedRewards) ?? undefined;

  return {
    totalFlipSupply: totalFlipSupply?.toFormattedString(),
    annualSystemCompoundedRewards,
    loading,
    error,
  };
};

export default useFlipSupply;
