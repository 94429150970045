import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { formatDistanceStrict } from 'date-fns';
import { useAccount } from 'wagmi';
import { flip$ } from '@/shared/assets/tokens';
import {
  Link,
  ValidatorOnlineStatusIndicator,
  ValidatorBadges,
  FlipLabel,
  SearchInput,
  Tooltip,
} from '@/shared/components';
import Toggle from '@/shared/components/atoms/Toggle';
import { TableV2 } from '@/shared/components/molecules/TableV2';
import QuestionMarkTooltip from '@/shared/components/QuestionMarkTooltip';
import { type CacheValidatorFragment } from '@/shared/graphql/generated/graphql';
import { useTableSort } from '@/shared/hooks';
import useBoolean from '@/shared/hooks/useBoolean';
import useFrontendPagination from '@/shared/hooks/useFrontendPagination';
import { COMPARATORS, TokenAmount, abbreviate, FLIP_SYMBOL, capitalize } from '@/shared/utils';
import {
  getAccountFundingDatesByIdSs58Query,
  getFundedAccountsByFunderQuery,
} from '../../queries/validatorQueries';
import { formatRelativeDateInPast } from '../../utils/time';

const auctionStatusMapper = (validator: AuctionValidator) => {
  if (validator.isCurrentAuthority) return 0;
  if (validator.isCurrentBackup) return 1;
  return 2;
};

export type AuctionValidator = CacheValidatorFragment & {
  rank: number | undefined;
  bid: BigNumber;
  isConnectedWallet: boolean;
  firstFunding: Date | undefined;
  latestFunding: Date | undefined;
};

const SORT_FUNCTIONS: ((validator: AuctionValidator) => string | BigNumber | number)[] = [
  (validator) => validator.rank || Infinity,
  (validator) => validator.alias || '',
  (validator) => validator.idSs58,
  (validator) => validator.bid,
  (validator) => validator.apyBp || '',
  (validator) => validator.latestFunding?.getTime() ?? 0,
  auctionStatusMapper,
  (validator) => validator.firstFunding?.getTime() ?? 0,
];

type AuctionTableProps = {
  validators: CacheValidatorFragment[];
  isLoading: boolean;
};

const AuctionTableRow = ({ validator }: { validator: AuctionValidator }) => (
  <tr
    className={classNames(
      'transition:ease-in duration-150 hover:cursor-pointer hover:bg-cf-gray-3',
      validator.isConnectedWallet && 'bg-cf-gray-3-5',
    )}
    onClick={() => {
      window.open(
        `${process.env.NEXT_PUBLIC_EXPLORER_URL}/validators/${validator.idSs58}`,
        '_blank',
      );
    }}
  >
    <td className="font-aeonikMono">{validator.rank}</td>
    <td className={classNames(!validator.isQualified && 'opacity-50')}>
      <div className="flex space-x-3 text-white">
        <ValidatorOnlineStatusIndicator isOnline={validator.isOnline} />
        <span>{validator.alias || 'Unnamed'}</span>
      </div>
    </td>
    <td className={classNames(!validator.isQualified && 'opacity-50', 'w-[12%]')}>
      <div className="flex items-center space-x-2 font-aeonikMono">
        <Link
          href={`${process.env.NEXT_PUBLIC_EXPLORER_URL}/validators/${validator.idSs58}`}
          target="_blank"
          rel="noreferrer"
          underline
        >
          {abbreviate(validator.idSs58, 4)}
        </Link>
      </div>
    </td>
    <td className={classNames(!validator.isQualified && 'opacity-50')}>
      <FlipLabel
        amount={new TokenAmount(validator.bid, flip$.decimals)}
        symbol={false}
        className="text-14"
      />
    </td>
    <td className={classNames(!validator.isQualified && 'opacity-50', 'font-aeonikMono')}>
      {validator.apyBp && <>{(validator.apyBp / 100).toFixed(2)}%</>}
    </td>
    <td className={classNames('font-aeonikMono', !validator.isQualified && 'opacity-50')}>
      {validator.latestFunding &&
        capitalize(formatRelativeDateInPast(validator.latestFunding.getTime()))}
    </td>
    <td>
      <div className="flex space-x-2 pr-4">
        <ValidatorBadges
          halfOpacity={!validator.isQualified}
          cacheValidator={validator}
          keys={['isCurrentAuthority', 'isCurrentBackup', 'isQualified']}
        />
      </div>
    </td>
    <td
      className={classNames(
        !validator.isQualified && 'opacity-50',
        'text-right font-aeonikMono text-cf-light-2',
      )}
    >
      {validator.firstFunding && (
        <Tooltip content={validator.firstFunding.toLocaleString()} pointer={false}>
          <span className="inline-flex">
            {formatDistanceStrict(validator.firstFunding, new Date())}
          </span>
        </Tooltip>
      )}
    </td>
  </tr>
);

const DEFAULT_ORDER = { col: 3, direction: 'DESC' } as const;

const AuctionTable: React.FC<AuctionTableProps> = ({ validators, isLoading }) => {
  const { sort, setCol } = useTableSort({ initialSort: DEFAULT_ORDER });
  const { value: hideUnqualifiedNodes, toggle: toggleUnqualifiedNodes } = useBoolean(true);

  const { address } = useAccount();
  const [filter, setFilter] = useState('');

  const { data: walletAccounts } = useQuery(getFundedAccountsByFunderQuery, {
    variables: { walletAddress: address ?? '' },
    context: { clientName: 'processor' },
    skip: address === undefined,
  });

  const { data: fundingDates } = useQuery(getAccountFundingDatesByIdSs58Query, {
    variables: {
      idSs58s: validators?.map((v) => v.idSs58) ?? [],
    },
    context: { clientName: 'processor' },
    skip: validators.length === 0,
  });

  const auctionValidators: AuctionValidator[] = useMemo(() => {
    let lastAuctionRank = 0;
    const walletValidatorsIdSs58 = new Set(
      walletAccounts?.events?.nodes?.map((val) => val.account.idSs58) ?? [],
    );
    const fundingDatesByIdSs58 = Object.fromEntries(
      fundingDates?.accounts?.nodes.map((d) => [
        d.idSs58,
        {
          first: d.firstFunding.nodes.at(0)?.event.block.timestamp,
          latest: d.latestFunding.nodes.at(0)?.event.block.timestamp,
        },
      ]) ?? [],
    );

    return validators
      .map((validator) => ({
        ...validator,
        bid: new BigNumber(validator.lockedBalance).plus(validator.unlockedBalance),
        isConnectedWallet: walletValidatorsIdSs58.has(validator.idSs58),
        firstFunding: fundingDatesByIdSs58[validator.idSs58]?.first,
        latestFunding: fundingDatesByIdSs58[validator.idSs58]?.latest,
      }))
      .sort((a, b) => b.bid.comparedTo(a.bid))
      .map((validator) => ({
        ...validator,
        firstFunding: validator.firstFunding ? new Date(validator.firstFunding) : undefined,
        latestFunding: validator.latestFunding ? new Date(validator.latestFunding) : undefined,
        rank: validator.isQualified
          ? // eslint-disable-next-line no-plusplus
            ++lastAuctionRank
          : undefined,
      }));
  }, [validators, walletAccounts, fundingDates]);

  const sortedAndFilteredValidators = useMemo(() => {
    const { col, direction } = sort;

    const sorted = auctionValidators
      .filter(
        (validator) =>
          !hideUnqualifiedNodes || validator.isQualified || validator.isCurrentAuthority,
      )
      .sort((a, b) => COMPARATORS[direction](SORT_FUNCTIONS[col](a), SORT_FUNCTIONS[col](b)));

    if (filter === '') return sorted;
    // need to escape regex special characters
    const regex = new RegExp(filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');

    return sorted.filter(
      (validator) => validator.alias?.match(regex) || validator.idSs58.match(regex),
    );
  }, [auctionValidators, sort, hideUnqualifiedNodes, filter]);

  const { data, paginationControls } = useFrontendPagination(sortedAndFilteredValidators, {
    initialLimit: 150,
    paginationLimits: [50, 100, 150, 200, 250],
  });

  return (
    <div
      className="flex flex-col space-y-4 py-16"
      style={{ height: filter !== '' ? '1480px' : undefined }}
    >
      <div className="flex flex-col items-start lg:flex-row lg:items-center">
        <div className="text-20 text-cf-white">Current Auction State</div>
        <div className="flex w-full grow flex-col items-start space-x-0 space-y-2 lg:w-auto lg:flex-row lg:items-center lg:justify-end lg:space-x-3 lg:space-y-0">
          <div className="order-2 flex space-x-2 pt-3 lg:order-none lg:pt-0">
            <Toggle value={hideUnqualifiedNodes} onToggle={toggleUnqualifiedNodes} />
            <span className="text-14">Hide unqualified nodes</span>
          </div>
          <div className="order-1 w-full truncate lg:order-none lg:w-160">
            <SearchInput
              value={filter}
              onChange={(value) => setFilter(value)}
              placeholder="Search by vanity name, node ID, or latest funding date"
            />
          </div>
        </div>
      </div>
      <div>
        <TableV2
          columns={[
            'Auction Position',
            'Vanity Name',
            'Node ID',
            `Bid (${FLIP_SYMBOL})`,
            {
              key: 'APY',
              children: (
                <div className="flex items-center gap-x-1">
                  <QuestionMarkTooltip
                    content={`Total expected rewards over the course of a year divided by the total ${FLIP_SYMBOL} balance for this validator.`}
                    disabled={false}
                  />
                  APY
                </div>
              ),
            },
            'Latest Funding',
            'Status',
            {
              alignment: 'right',
              name: 'Age',
            },
          ]}
          isLoading={isLoading}
          rows={data.edges.map(({ node: validator }) => (
            <AuctionTableRow key={validator.idSs58} validator={validator} />
          ))}
          sorting={{
            setCol,
            sort,
          }}
          paginationControls={paginationControls}
        />
      </div>
    </div>
  );
};

export default AuctionTable;
